import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { NgClass, NgIf } from '@angular/common';
import { Component, DestroyRef, ElementRef, inject, ViewEncapsulation } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { provideAnimations } from '@angular/platform-browser/animations';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { ToastModule } from 'primeng/toast';
import { finalize } from 'rxjs';
import { AuthTokenService } from '../../../services/auth-token.service';
import { AuthService } from '../../../services/auth.service';
import { CreateAccountRequest, LoginRequest } from '../../../services/dtos';
import { AuthEnum } from '../../shared/models/enums/auth.enum';
import { ButtonEnum } from '../../shared/models/enums/button.enum';
import { GlobalEnum } from '../../shared/models/enums/global';
import { InputEnum } from '../../shared/models/enums/input.enum';
import { AddNewComponent } from '../add-new/add-new.component';
import { ButtonComponent } from '../button/button.component';
import { FormComponent } from '../forms/form/form.component';
import { InputComponent } from '../forms/input/input.component';
import { LoaderComponent } from '../loader/loader.component';
import { RegisterStepsComponent } from '../register-steps/register-steps.component';

export const responseErrorInfo: any = {
  "PromoCodeNotFound": "Código de criação inválido",
  "UserAlreadyExists": "Usuário já existente",
  "Invalid user or password": "Usuário ou senha incorretos",
  "Invalid code": "Código inválido",
  "Store already exists": "Armazenamento já existente"
}

type BodyRequestType = {

  email: string;
  password: string;
  code?: string;
}

@Component({
  selector: 'app-auth',
  standalone: true,
  imports: [
    InputComponent,
    ButtonComponent,
    RegisterStepsComponent,
    FormComponent,
    AddNewComponent,
    NgIf,
    NgClass,
    ReactiveFormsModule,
    MatIconModule,
    ToastModule,
    LoaderComponent,
    ToastrModule
  ],
  providers: [
    provideAnimations(),
  ],
  templateUrl: './auth.component.html',
  styleUrl: './auth.component.scss',
  animations: [
    // Slide Step 1
    trigger('slideStepOne', [
      state(
        'slideStart',
        style({
          transform: 'translateX(0)',
          transition: 'ease-in-out 0.3s',
          opacity: 1,
        })
      ),
      state(
        'slideFinish',
        style({
          transform: 'translateX(-477px)',
          transition: 'ease-in-out 0.3s',
          position: 'absolute',
        })
      ),
      transition('closed => slide', [animate('0.2s')]),
    ]),

    // Slide Step 2
    trigger('slideStepTwo', [
      state(
        'slideStart',
        style({
          transform: 'translateX(477px)',
          transition: 'ease-in-out 0.3s',
          opacity: 0,
        })
      ),
      state(
        'slideFinish',
        style({
          transform: 'translateX(0)',
          transition: 'ease-in-out 0.3s',
          opacity: 1,
        })
      ),
      transition('closed => slide', [animate('0.2s')]),
    ]),
  ],
  encapsulation: ViewEncapsulation.None,
})
export class AuthComponent {
  private readonly destroyRef = inject(DestroyRef);

  public loginForm!: FormGroup;
  public register = false;
  public registerForm!: FormGroup;

  // Steps
  isNextStep = false;
  isStepOne = false;
  isStepTwo = false;

  // Input password visibility
  inputPasswordType = 'password';
  isValid!: boolean;
  visibilityPassword = false;

  isLoading = false;

  // Enums
  authorizeConnectionMLText = AuthEnum.authorizeConnectionMLText;
  capivaraLogoText = GlobalEnum.capivaraLogoText;
  connectMlText = ButtonEnum.connectMlText;
  connectShopeeText = ButtonEnum.connectShopeeText;
  enterNoLinkAccountText = ButtonEnum.enterNoLinkAccountText;
  enterText = ButtonEnum.enterText;
  enterYourEmailText = InputEnum.enterYourEmailText;
  enterYourPasswordText = InputEnum.enterYourPasswordText;
  enterYourRegisterCodeText = InputEnum.enterYourRegisterCodeText;
  headingText = AuthEnum.headingText;
  hintOutlineText = GlobalEnum.hintOutlineText;
  letsStartText = AuthEnum.letsStartText;
  linkAccountsLaterText = AuthEnum.linkAccountsLaterText;
  logToYourAccountText = AuthEnum.logToYourAccountText;
  noLoginYetText = AuthEnum.noLoginYetText;
  openInNewText = ButtonEnum.openInNewText;
  passwordVisibilityOffText = InputEnum.passwordVisibilityOffText;
  registerActionText = AuthEnum.registerActionText;
  registerText = AuthEnum.registerText;
  setSurnameText = InputEnum.setSurnameText;
  whichAccountNameText = InputEnum.whichAccountNameText;
  whichStoreWouldYouLinkText = AuthEnum.whichStoreWouldYouLinkText;
  goBackToLoginText = AuthEnum.goBackToLoginText;

  constructor(
    private readonly fb: FormBuilder,
    private readonly authTokenService: AuthTokenService,
    private readonly elementRef: ElementRef,
    private readonly toastr: ToastrService,
    private readonly route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    // FormGroup de login.
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
    });

    // FormGroup de cadastro.
    this.registerForm = this.fb.group({
      ...this.loginForm.controls,
      code: ['', Validators.required],
      surname: [''],
    });

    this.route.queryParams.subscribe(params => {
      const registerCode = params['registerCode'];
      if (registerCode) {
        this.register = !this.register;
        this.isStepOne = !this.isStepOne;
        this.triggerClickNextStep(this.register);

        console.log()
        this.registerForm.patchValue({ code: registerCode });
      }
    });
  }

  /**
   * @description
   * Se o usuário estiver no formulário de cadastro, chama o step 2.
   * Obtém os dados preenchidos do usuário, a fim de salvar no backend.
   */
  nextStep() {
    this.isNextStep = !this.isNextStep;
    this.isStepOne = false;
    this.isStepTwo = true;

    this.submitRegisterForm();
  }


  /**
   * Change password visibility.
   */
  togglePasswordVisibility(): void {
    this.visibilityPassword = !this.visibilityPassword;
    this.inputPasswordType =
      this.inputPasswordType === 'password' ? 'text' : 'password';
  }

  /**
   * @description
   * Submit do formulário de cadastro.
   * Se register for "true", executa o cadastro.
   * Monta o payload e envia para o backend.
   */
  submitRegisterForm(): void {
    this.isLoading = true;

    if (this.register) {
      this.authTokenService.createAccount(this.registerForm.value as CreateAccountRequest).pipe(
        takeUntilDestroyed(this.destroyRef),
        finalize(() => {
          this.isLoading = false
        })
      ).subscribe();
    }
  }

  /**
   * @description
   * Submit do formulário de login.
   * Se register for "false", executa o login.
   * Monta o payload e envia para o backend.
   */
  submitLoginForm(): void {
    this.isLoading = true;
    if (!this.register) {
      if (!this.loginForm.valid) {
        this.toastr.error('Credenciais inválidas!');

        this.isLoading = false;
        return;
      }

      this.authTokenService.login(this.loginForm.value as LoginRequest).pipe(
        takeUntilDestroyed(this.destroyRef),
        finalize(() => {
          this.isLoading = false
        })
      ).subscribe();
    }
  }

  /**
   * @description
   * Revalida o estado do control de login e cadastro.
   */
  revalidateForm() {
    this.loginForm.updateValueAndValidity();
    this.registerForm.updateValueAndValidity();
  }

  /**
   * @description
   * Ao navegar com tab, na tela de cadastro, limpa os campos email e password.
   * @param register
   * @returns
   */
  triggerClickNextStep(register: boolean) {
    const idEmail = register ? '#registerEmail' : '#loginEmail';
    const idPassword = register ? '#registerPassword' : '#loginPassword';

    const elEmail = this.elementRef.nativeElement.querySelector(idEmail);
    const elPassword = this.elementRef.nativeElement.querySelector(idPassword);

    const elValue = this.elementRef.nativeElement.querySelector(idEmail)?.value;

    elPassword?.click();
    elEmail?.click();

    this.loginForm.reset();

    if (elValue) {
      elEmail.value = '';
      elPassword.value = '';
    }

    return elEmail;
  }

  get hasToken() {
    return this.authTokenService.hasToken();
  }
}
