<div class="register-steps">
  <div class="register-steps__item" [ngClass]="{ '--active': isStepOne }">
    <span class="step-number">1</span>
    <strong class="step-name">{{ registerStepText }}</strong>
  </div>
  <span class="register-steps__separator"></span>
  <div class="register-steps__item" [ngClass]="{ '--active': isStepTwo }">
    <span class="step-number">2</span>
    <strong class="step-name">{{ linkAccountsText }}</strong>
  </div>
</div>
