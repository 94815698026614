import { NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';
import { AuthEnum } from '../../shared/models/enums/auth.enum';

@Component({
  selector: 'app-register-steps',
  standalone: true,
  imports: [NgClass],
  templateUrl: './register-steps.component.html',
  styleUrl: './register-steps.component.scss',
})
export class RegisterStepsComponent {
  @Input() isStepOne = false;
  @Input() isStepTwo = false;

  // Enums
  registerStepText = AuthEnum.registerStepText;
  linkAccountsText = AuthEnum.linkAccountsText;
}
