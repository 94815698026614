<div class="auth-container" *ngIf="!hasToken">
  <div class="logo">
    <img src="../../../assets/images/icons/logo-capivarapro.svg" [attr.alt]="capivaraLogoText" />
  </div>

  <div class="form" [ngClass]="register ? '--register' : '--login'">
    <div class="form__heading" [attr.role]="headingText" aria-level="1">
      <p>{{ register ? letsStartText : logToYourAccountText }}</p>
    </div>

    <form [formGroup]="register ? registerForm : loginForm">
      <div class="form__fields" [@slideStepOne]="!isNextStep ? 'slideStart' : 'slideFinish'">
        <div>
          <div class="form__input-box">
            <app-input id="{{ register ? 'registerEmail' : 'loginEmail' }}" [label]="enterYourEmailText"
              [type]="'email'" [formCtrl]="loginForm.controls['email']" placeholder="Insira seu e-mail"
              [iconInput]="'../../../assets/images/icons/email.svg'" (keyupEventEmitter)="revalidateForm()"></app-input>
          </div>
          <div class="form__input-box">
            <app-input id="{{ register ? 'registerPassword' : 'loginPassword' }}" [label]="enterYourPasswordText"
              (keydown.enter)="submitLoginForm()" [type]="inputPasswordType !== 'text' ? 'password' : 'text'"
              [formCtrl]="loginForm.controls['password']" [iconInput]="'../../../assets/images/icons/key-passoword.svg'"
              [isValidCredentials]="isValid && !register" (keyupEventEmitter)="revalidateForm()"></app-input>
            <mat-icon class="form__input-box__visibility" aria-hidden="false"
              [attr.aria-label]="passwordVisibilityOffText" fontIcon="{{
                  !visibilityPassword ? 'visibility_off' : 'visibility'
                }}" (click)="togglePasswordVisibility()"></mat-icon>
          </div>

          @if (register) {
          <div class="form__input-box">
            <app-input [label]="enterYourRegisterCodeText"
              [iconInput]="'../../../assets/images/icons/icon-cadastro.svg'"
              [formCtrl]="registerForm.controls['code']" (keyupEventEmitter)="revalidateForm()"></app-input>
          </div>
          }
        </div>

        <div class="form__button">
          <ng-container *ngIf="register">
            <app-button [disabled]="!registerForm.valid" [text]="registerActionText" [isLoading]="isLoading"
              (click)="registerForm.valid && submitRegisterForm()"></app-button>

            <p>
              <button class="link" (click)="register = !!register && (isStepOne = !isStepOne)">
                {{ goBackToLoginText }}
              </button>
            </p>
          </ng-container>

          <ng-container *ngIf="!register">
            <app-button [disabled]="!loginForm.valid" [text]="enterText" [isLoading]="isLoading"
              (click)="loginForm.valid && submitLoginForm()">
            </app-button>
            <p>
              {{ noLoginYetText }}
              <button class="link" type="button" (click)="
                    register =
                      !register &&
                      (isStepOne = !isStepOne) &&
                      triggerClickNextStep(register)
                  ">
                {{ registerText }}
              </button>
            </p>
          </ng-container>
        </div>
      </div>
    </form>

  </div>
</div>